<template>
    <r-e-drawer class="drawer-mass-info" title="消息推送管理" :visible.sync="drawerVisible" :size="1700" modal wrapper-closable
        drawer-wrapper>
        <div class="drawer-container">
            <el-input v-model="formSearch.leasorName" placeholder="请输入租户" style="margin: 0 7px; width: 200px"
                size="small" />
            <el-date-picker v-model="StartAndEndDATE" type="daterange" range-separator="至" start-placeholder="开始日期"
                style="margin: 0 7px;" end-placeholder="结束日期" size="small" />
            <el-select v-model="formSearch.typeCode" size="small" placeholder="请选择消息类型" style="margin-right: 10px; width: 200px">
                <!--                <el-option label="全部" :value="null"/>-->
                <el-option v-for="(item,index) in masslist" :label="item.codeName" :value="item.code" :key="index"/>

            </el-select>
            <el-select v-model="formSearch.pushType" size="small" placeholder="请选择推送渠道"
                style="margin-right: 10px; width: 200px">
                <!--                <el-option label="全部" :value="-1"/>-->
                <el-option label="短信" value="短信" />
                <el-option label="小程序" value="小程序" />
            </el-select>
            <el-select v-model="formSearch.status" size="small" placeholder="请选择状态"
                style="margin-right: 10px; width: 200px">
                <!--                <el-option label="全部" :value="-1"/>-->
                <el-option value="正常" label="正常" />
                <el-option value="失败" label="失败" />
            </el-select>
            <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset" size="small" style="margin: 0 7px;">重置</el-button>


        </div>

        <r-e-table ref="communityTableRef1" :dataRequest="messageManagement" :columns="tableWorkOrderColumn"
            :query="formSearch" :height="750">
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">消息详情</span>
                        <span class="table-btn" v-if="row.status == 0" @click="aissue(row)">重新下发</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>



        <r-e-dialog title="消息内容" class="layer-contract-detail"  :visible.sync="detailsDialogVisible" show-footer top="10vh"
            @click-submit="detailsDialogVisible = false" @click-cancel="detailsDialogVisible = false"
            @close="detailsDialogVisible = false">
            <bill-details ref="billDetails" :billData="billData" />

        </r-e-dialog>




    </r-e-drawer>
</template>

<script>
import { tableWorkOrderColumn } from "@/views/rental-management/tenant-management/data"
import * as management from "@/api/tenant-management";
import { timeFormat } from "@custom/index";

export default {
    name: "drawer-tenants-info",
    data() {
        return {
            // person:'',
            masslist:[],
            detailsDialogVisible: false,
            billData: '',
            StartAndEndDATE: '',
            drawerVisible: false,
            tableWorkOrderColumn: tableWorkOrderColumn(this),
            formSearch: {
                //    status: null,
                state: -1,
                leasorName: '',
                typeCode:'',
                startDate: '',
                endDate: '',
                pushType: null,
                status: null
            },

        }
    },
    components: {
        billDetails: () => import("./messageDetails.vue"),
    },
    mounted() {
        this.messType();
    },
    methods: {
        // 所有消息类型
        messType(){
            let that=this
            management.messageType().then((res)=>{
                // console.log(res);
                that.masslist=res.list

                // console.log(that.masslist);

            })
        },

        // 消息列表
         messageManagement(params) {
            return management.message(params);
        },
        // 详情
        look(obj) {
            this.detailsDialogVisible = true;
            this.billData = obj
        },
        // 重新下发
        aissue(row) {
            let that=this
            const params = {
                uuid: row.uuid
            }
            management.reissue(params).then((res) => {
                that.$message({ message: res.msg, type: 'success' });
                //刷新列表
                that.handleSearch();
            });
        },
        //   查询
        handleSearch() {
            this.$refs["communityTableRef1"].getTableData();
        },
        // 重置
        handleReset() {
            this.formSearch = { status: null, pushType: null,  leasorName: '',typeCode:'', startDate: '', endDate: '' };
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openmessage() {
            this.drawerVisible = true;
        },
        closeDrawer() {
            this.$emit('handleSearch');
            this.drawerVisible = false;
        },
    },
    watch: {
        drawerVisible(value) {
            if (!value) this.closeDrawer();
        },
        StartAndEndDATE(value) {
            if (value) {
                let [startDate, endDate] = value;
                this.formSearch.startDate = timeFormat(startDate);
                this.formSearch.endDate = timeFormat(endDate);
            } else {
                this.formSearch.startDate = null;
                this.formSearch.endDate = null;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.drawer-mass-info {
    .drawer-container {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }


}

// /deep/.table-limit-height{
//     height: 80%;
// }
</style>
